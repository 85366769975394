@import "../../../styles/variables.module.scss";

.button {
  background: none;
  padding: 0;
  margin: 0;
  color: $link-color;
  font-weight: inherit;
  font-size: inherit;
  line-height: 1;
  box-shadow: none;
  transition: none;
  
  &:hover {
    background: none;
    font-weight: inherit;
    color: $link-hover-color;
  }

  &:disabled {
    background: none;
    color: inherit;
  }
  
  &:active {
    background-color: transparent !important;
  }
}