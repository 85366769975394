@import "../../../styles/variables.module.scss";

.links {
  text-align: center;
  
  @media (min-width: $grid-breakpoint-md) {
    text-align: right;
  }
}

.logo {
  width: 6rem;
}

.hover:hover {
  cursor: pointer;
}