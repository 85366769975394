@import "../../../styles/variables.module.scss";

.wrapper {
  .item {
    .cardTitle {
      font-family: $font-family-sans-serif !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;

      a {
        max-width: 80%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .deleteButtonWrapper {
        width: 32px;

        .deleteButton {
          display: none;
          color: $gray-300;
        }
      }
    }

    &:hover {
      .cardTitle .deleteButtonWrapper .deleteButton {
        display: flex;

        &:hover {
          color: $link-hover-color;
        }
      }
    }
  }

  .addProject {
    box-shadow: none;
    background: none;
    border: 1px solid $primary;
  }

  .sansSerif {
    font-family: $font-family-sans-serif !important;
  }
}
