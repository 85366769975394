@import "../../../styles/variables.module.scss";

.navbar {
  z-index: 1;
  
  &::before {
    border-color: $primary;
    border-image: none;
  }

}

.navlink:hover {
  cursor: pointer;
}

#banner {
  z-index: 1;
  opacity: 1;
  
  &:hover {
    cursor: pointer;
  }
}

.linkList {
  display: flex;
  list-style: none;
}

@media (max-width: 767.98px) {
  .collapse {
    position: absolute;
    left: 0;
    margin-top: 3rem;
    padding-bottom: 1rem;
    background: $white;
    width: 100%;
  }
}